// Overrides for the Button and Button Group components

//
// > BUTTONS
//

// >> Base
.btn {
  font-weight: $btn-font-weight;
  text: {
    transform: uppercase;
    decoration: none;
  }
  letter-spacing: .04em;
  white-space: normal;
  border-width: $btn-border-width;
}

// >> Variants
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}
.btn-link {
  color: $link-color;
}


// hard-code red/blue since we removed primary/secondary; in favor of red/blue being more intuitive
.btn-red {
  @include button-variant($chili);
  background-color: $chili;
  @include hover() {
    color: $white;
    background-color: $red;
  }
  &.active,
  &:active {
    background-color: lighten($chili, 5%);
  }
}
.btn-blue {
  @include button-variant($midnight);
  background-color: $blue;
  @include hover() {
    color: $white;
    background-color: $azurite;
  }
  &.active,
  &:active {
    background-color: lighten($blue, 10%);
  }
}
.btn-outline-red {
  color: $chili;
  border-color: $chili;
  &:hover {
    color: $white;
    background-color: $chili;
  }
}
.btn-outline-blue {
  color: $blue;
  border-color: $blue;
  &:hover {
    color: $white;
    background-color: $blue;
  }
}
.btn-outline-white {
  color: $white;
  border-color: $white;
  &:hover {
    color: $gray-800;
    background-color: $white;
  }
}


// >> Variant Accessibility
.btn-success,
.btn-info,
.btn-outline-info {
  &:hover {
    color: $midnight;
  }
}
.btn-warning {
  &:hover {
    color: $black;
  }
}
.btn-outline-info {
  color: $azurite;
}
.btn-outline-warning {
  color: $ash;
  &:hover {
    color: $black;
  }
}
.btn-outline-light {
  color: $ash;
}

// >> Arrow Buttons
.btn-arrow {
  &::after {
    content: "\00a0»";
  }
}


//
// > BUTTON GROUP
//

// >> Justified
.btn-group-justified {
  display: flex;

  .btn {
    flex-basis: 0;
    flex-grow: 1;
  }
}
// >> Block
.btn-group-block {
  @extend .btn-block;
  .btn {
    @extend .btn-block;
  }
  .dropdown-menu {
    min-width: 100%;
  }
}

