// >> Callouts
.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  @include border-radius();

  h4 {
    margin-top: 0;
    margin-bottom: .25rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    @include border-radius();
  }

  + .callout {
    margin-top: -.25rem;
  }
}

@each $color, $value in $colors {
  .callout-#{$color} {
    @include callout-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .callout-#{$color} {
    @include callout-variant($value);
  }
}

// ensure color contrast
.callout-silver,
.callout-cool-gray,
.callout-warm-gray,
.callout-leaf,
.callout-sky,
.callout-info,
.callout-light,
.callout-success,
.callout-warning {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $dark-silver;
    @each $color, $value in $colors {
      // ensure elements using color classes aren't overwritten
      &.text-#{$color} {
        color: $value;
      }
    }
    @each $color, $value in $theme-colors {
      // ensure elements using color classes aren't overwritten
      &.text-#{$color} {
        color: $value;
      }
    }
  }
}
