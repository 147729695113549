// Hover utility

.hover:hover,
.hover:focus {
  .hover-text-underline {
    text-decoration: underline;
  }
  // stylelint-disable-next-line selector-no-qualifying-type
  img.hover-img-zoom-in {
    // stylelint-disable-next-line property-disallowed-list
    transition: ease .25s;
    transform: scale(1.03);
  }
}
