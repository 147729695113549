// >> Background Wrappers
.background-wrapper {
  width: 100%;
  padding: 3rem 0;
}

// >>> Triangles Top Left
.bg-triangles-top-left {
  background: {
    image: url("img/triangles-top-left.svg");
    repeat: no-repeat;
    position: 0 0;
    size: auto;
    blend-mode: multiply;
  }

}
.bg-red.bg-triangles-top-left {
  background: {
    image: url("img/triangles-top-left-red.svg");
    blend-mode: initial;
  }
}
.bg-blue.bg-triangles-top-left {
  background: {
    image: url("img/triangles-top-left-blue.svg");
    blend-mode: initial;
  }
}
// >>> Triangles Top Right
.bg-triangles-top-right {
  background: {
    image: url("img/triangles-top-right.svg");
    repeat: no-repeat;
    position: top right;
    size: auto;
    blend-mode: multiply;
  }
}
.bg-red.bg-triangles-top-right {
  background: {
    image: url("img/triangles-top-right-red.svg");
    blend-mode: initial;
  }
}
.bg-blue.bg-triangles-top-right {
  background: {
    image: url("img/triangles-top-right-blue.svg");
    blend-mode: initial;
  }
}
// >>> Triangles Centered
.bg-triangles-centered {
  background: {
    image: url("img/triangles-centered.svg");
    repeat: no-repeat;
    position: center bottom;
    size: auto;
    blend-mode: multiply;
  }
}
.bg-red.bg-triangles-centered {
  background: {
    image: url("img/triangles-centered-red.svg");
    blend-mode: initial;
  }
}
.bg-blue.bg-triangles-centered {
  background: {
    image: url("img/triangles-centered-blue.svg");
    blend-mode: initial;
  }
}
// >>> Trilines
.bg-trilines {
  background: {
    image: url("img/trilines.svg");
    size: auto 305px;
    position: top right;
    position: top 0 right -1em;
    repeat: no-repeat;
  }

  p,
  li,
  hr {
    background-color: rgba(255, 255, 255, .7);
  }

  @each $color, $value in $colors {
    &.bg-#{$color} {
      p,
      li,
      hr {
        background-color: rgba($value, .7);
      }
    }
  }
}
