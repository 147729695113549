// Overrides & custom for the Collapse component

// Make entire accordion header clickable for accessibility and UX
.accordion {
  .card-header {
    padding: 0;

    h2,
    h3,
    h4,
    h5,
    h6,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      padding: 0;
    }

    .btn {
      display: block;
      width: 100%;
      padding: .75rem 2.25rem .75rem 1.25rem;
      color: $dark-silver;
      text-align: left;
      text-transform: none;

      &::after {
        position: absolute;
        top: 2px;
        right: 10px;
        display: inline-block;
        // stylelint-disable-next-line
        font-family: "Material Icons Sharp";
        font-size: 2em;
        font-variant-ligatures: no-common-ligatures;
        content: "expand_more";
      }

    }

    @include media-breakpoint-up(sm) {
      .btn {
        padding-right: 3rem;
        &::after {
          right: 20px;
        }
      }

    }
    .btn[aria-expanded="true"] {
      &::after {
        content: "expand_less";
      }
    }
  }
}
