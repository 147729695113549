// Overrides to typography

/*
* > BLOCKQUOTE
*/
.blockquote {
  @include font-size($font-size-base * 1.25);
  padding: .75rem 1.5rem;
  margin-bottom: 1.5rem;
  border-color: $blue;
  border-style: solid;
  border-width: 0 0 0 5px;

  &.blockquote-reverse {
    border-width: 0 5px 0 0;
  }
}

/*
* > BODY
*/
body {
  color: $ash;
}

/*
* > HEADINGS
*/

// >> h1 and h2
h1,
h2,
.h1,
.h2 {
  margin: $heading-margins;
  @extend .heading-style;
}

// >> h3 - h6
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  margin: $heading-margins;
  font-family: $font-family-sans-serif;
  color: $dark-silver;
}

/*
* > LINKS
*/
a {
  font-weight: 700;
  text-decoration: underline;
}
.mailto {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}
