// Overrides & Custom for the Card component

// >> Card Header
.card {
  .card-header {
    background-color: $card-cap-bg;
  }
}

.card[class*=" bg-"] {
  .card-header {
    background-color: $card-cap-bg-darken;
  }
}

// check for color contrast
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    // check for color contrast
    @if $value == $sky or $value == $leaf {
      .card-header {
        color: $midnight;
      }
    }
    @else if $value == $bloom {
      .card-header {
        color: $black;
      }
    }
  }
}


// >> Clickable Card (Borderless & Landing Grid)
.card-clickable {
  border: {
    top-width: 0;
    right-width: 1px;
    bottom-width: 1px;
    left-width: 0;
    color: $sky;
  }

  .card-body {
    padding: 2.5rem;
    color: inherit;
    text-decoration: none;
  }

  .card-clickable-link {
    @extend .mb-0;
    color: $link-color;
  }

  &:hover {
    text-decoration: none;
  }
}

// >> Borderless Card
.card-borderless {
  @extend .border-0;

  &:hover {
    .card-clickable-link {
      text-decoration: underline;
    }
  }
}

// >> Landing Grid
.card-landing-grid {
  border-style: solid;
  border-width: 2px 0 0 2px;
  box-shadow: 3px 3px 0 0 rgba($sky, .5);

  &.landing-oasis {
    box-shadow: 3px 3px 0 0 rgba($oasis, .5);
  }
  &.landing-azurite {
    box-shadow: 3px 3px 0 0 rgba($azurite, .5);
  }
  &.landing-blue {
    box-shadow: 3px 3px 0 0 rgba($blue, .5);
  }
  &.landing-midnight {
    box-shadow: 3px 3px 0 0 rgba($midnight, .5);
  }
  &.landing-leaf {
    box-shadow: 3px 3px 0 0 rgba($leaf, .5);
  }
  &.landing-river {
    box-shadow: 3px 3px 0 0 rgba($river, .5);
  }
  &.landing-bloom {
    box-shadow: 3px 3px 0 0 rgba($bloom, .5);
  }
  &.landing-red {
    box-shadow: 3px 3px 0 0 rgba($red, .5);
  }
  &.landing-chili {
    box-shadow: 3px 3px 0 0 rgba($chili, .5);
  }
  &.landing-ash {
    box-shadow: 3px 3px 0 0 rgba($ash, .5);
  }
  &.landing-silver {
    box-shadow: 3px 3px 0 0 rgba($silver, .5);
  }
}

