// Photo Gallery custom component

.az-gallery,
.az-gallery-inner {
  width: 100%;
  height: 100%;
}

.az-gallery-modal .close {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 5000;
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 3rem;
  color: $white;
  opacity: 1;
}


.ua-gallery-item,
.az-gallery-item {
  width: 100%;
  height: 100%;
}
.ua-gallery-carousel-image,
.az-gallery-img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70%;
  max-height: 80%;
  transform: translate(-50%, -50%);
}

.ua-gallery-caption,
.az-gallery-caption {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  width: 100%;
  max-width: 850px;
  padding: .75rem;
  margin: 0;
  transform: translateX(-50%);
}

.ua-gallery-caption p:last-of-type,
.az-gallery-caption p:last-of-type {
  margin-bottom: 0;
}

.photo-gallery-grid-img {
  max-width: 100%;
}
