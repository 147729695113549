// Overrides for Pagination
//
// > PAGINATION
//
.page-link {
  color: $pagination-color;

  &:hover {
    color: $pagination-hover-color;
  }
}
